.mentions {
  margin: 0;
}

.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 3px;
  min-height: 28px;
  outline: none;
}
.mentions--multiLine .mentions__input {
  border: none;
  padding: 4px;
  line-height: 1.5;
  --tw-ring-color: unset;
  --tw-ring-shadow: unset;
  outline: none;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #3d6eee;
  color: white;
}

.mentions__mention {
  position: relative;
  pointer-events: none;
}

.task__mentions {
  border: 1px solid rgb(209 213 219);
  border-radius: 8px;
  width: 100%;

  .mentions__input {
    padding: 6px;
  }
}

.dashboard__comment .mentions__input {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important;
}
